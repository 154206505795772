import moment from 'moment';
import { cloneDeep } from "lodash";

export default {
  data: () => ({
    dates: [],
    dateType: [],
  }),

  methods: {
    processDates(dates, dateType) {
      const startDate = moment(dates[0]);
      const endDate = moment(dates[1]);
      this.dateType = dateType;

      this.dates[0] = startDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      this.dates[1] = endDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    },
    setCommonFilterProperties() {
      return {
        startDate: this.dates[0],
        endDate: this.dates[1],
        dateType: this.dateType,
      };
    },
    getFilteredParams(filter) {
			return Object.entries(filter)
				.filter(([key, value]) => value && value !== "")
				.reduce((element, [key, value]) => ({ ...element, [key]: value }), {})
		},
		buildSearchParams(filteredParams, type) {
			const formattedFilterParams = cloneDeep(filteredParams)

			this.deleteEmptyParams(formattedFilterParams)

			return new URLSearchParams({
				...formattedFilterParams,
				page: this.page - 1,
				size: this.itemsPerPage,
				sort: "id,desc"
			})
		},
		deleteEmptyParams(params) {
			Object.keys(params).forEach((key) => {
				if (["", null, undefined].some(item => item === params[key])) delete params[key]
			})
		},
    clearSessionStorage() {
      const keyToken = '@auth/token';
      const valueToken = sessionStorage.getItem(keyToken);
      const keyUser = '@auth/user';
      const valueUser = sessionStorage.getItem(keyUser);
      // const appOrigin = '@sdi/app_origin';
      // const userOrigin = sessionStorage.getItem(appOrigin);
      const selectedDate = 'selectedDate';
      const valueSelectedDate = sessionStorage.getItem(selectedDate);
      sessionStorage.clear();
      sessionStorage.setItem(keyToken, valueToken);
      sessionStorage.setItem(keyUser, valueUser);
      sessionStorage.setItem(selectedDate, valueSelectedDate);
      // sessionStorage.setItem(appOrigin, !Boolean(userOrigin) ? "BACKOFFICE" : userOrigin);
    },
  },

  beforeDestroy() {
    this.dates = [];
    this.dateType = [];
  }
}
