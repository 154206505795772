<template>
  <v-sheet
    height="60px"
    width="100%"
    color="wine"
    class="d-flex align-center rounded-lg white--text text-subtitle-1">
    <v-container
      fluid>
      <v-row
        class="align-center">
        <v-col
          class="d-flex justify-end"
          cols="1">
          Protocolo
        </v-col>
        <v-col
          class="d-flex flex-row pl-0"
          cols="5">
          <v-col
            :cols="$vuetify.breakpoint.lgAndDown ? 3 : 2">
            Data e hora
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.lgAndDown ? 3 : 4">
            Criado por
          </v-col>
          <v-col
            cols="6">
            Nome do arquivo
          </v-col>
        </v-col>
        <v-col
          cols="2">
          Operadora
        </v-col>
        <v-col>
          <v-row
            no-gutters>
            <v-col
              :cols="$vuetify.breakpoint.lgAndDown ? 4 : 5">
              Etapa
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.lgAndDown ? 7 : 6">
              Status
            </v-col>
            <v-col
              class="d-flex justify-end"
              cols="1">
              Ações
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: "DataLoadHeader"
}
</script>
