/* eslint-disable */
import HttpService from '../HttpService';
export default class CarrierService {
    constructor() {
      this._httpService = new HttpService('/contract/carrier');
    }

    async FindAll() {
      return await this._httpService.get('?onlyMainFields=true');
    }

    async FindAllWithFilters(queryString) {
      return await this._httpService.get(`${queryString}`);
    }

    async FindById(id) {
      return await this._httpService.get(`/${id}`);
    }

    async GetAllowedDates(ids, movementType, eventDate) {
      return await this._httpService.get(`/movement-calendar?parentContractIds=${ids}&movementType=${movementType.toUpperCase()}${eventDate ? `&eventDate=${eventDate}` : ''}`);
    }
 };
